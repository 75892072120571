import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { Link } from 'gatsby';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { Subpath, linkResolver } from '../utils/linkResolver';
import { isSelfServeButMobile, schemaDatoButton } from './schemaDatoButton';

export const schemaCardButtonLink = createSchema<any, any>({
  as: {
    path: ['ctaButton'],
    fn: ({ ctaButton }: any) => {
      if (ctaButton.internalUrl) {
        return Link;
      }
      if (ctaButton.url) {
        return 'a';
      }
      if (isSelfServeButMobile(ctaButton?.url)) {
        // Don't display button if it's a self-serve button and screen size is mobile
        return 'div';
      }
      if (ctaButton?.onClick) {
        return 'div';
      }
      return 'div';
    },
  },
  onClick: 'onClick',
  button: {
    path: ['ctaButton', 'isTitleResponsive', 'screenSize', 'locale'],
    fn: ({ ctaButton, isTitleResponsive, screenSize, locale }: any) => {
      if (ctaButton) {
        const { onClick, to, href, ...btn } = morphism(schemaDatoButton, {
          ...ctaButton,
          as: 'span',
          screenSize: isTitleResponsive && screenSize,
          locale,
        });
        return <ButtonTracking component={ButtonV2} {...btn} />;
      }
      if (isSelfServeButMobile(ctaButton?.url)) {
        // Don't display button if it's a self-serve button and screen size is mobile
        return <></>;
      }
      const cta = morphism(schemaDatoButton, {
        ...ctaButton,
        locale,
        as: 'span',
      });

      return (
        <ButtonTracking
          component={ButtonV2}
          {...cta}
          labelTracking={cta.title}
        />
      );
    },
  },
  linkAttributes: {
    path: [
      'ctaButton',
      'locale',
      'onClick',
      'isClientLink',
      'isBlogArticle',
      'params',
      'urlParams',
    ],
    fn: ({
      ctaButton,
      onClick,
      locale,
      isClientLink,
      isBlogArticle,
      params,
      urlParams,
    }: any) => {
      if (ctaButton.internalUrl) {
        const subpath = isClientLink
          ? Subpath.Client
          : isBlogArticle
            ? Subpath.BlogArticles
            : undefined;

        return {
          to: linkResolver(
            ctaButton.internalUrl,
            locale,
            params || urlParams,
            subpath,
          ),
        };
      }

      if (!ctaButton?.url && !ctaButton?.internalUrl && !ctaButton?.onClick) {
        return {
          onClick: (event?: MouseEvent) => {
            if (onClick) onClick(event);
          },
          role: 'button',
          tabIndex: '0',
        };
      }
      if (!ctaButton?.url && !ctaButton?.internalUrl && !ctaButton?.onClick) {
        return `#${ctaButton?.title?.replace(/\W+/g, '-')}`;
      }

      if (isSelfServeButMobile(ctaButton?.url)) {
        // Don't display button if it's a self-serve button and screen size is mobile
        return null;
      }

      if (ctaButton.url) {
        return {
          href: ctaButton.url,
        };
      }

      if (ctaButton?.onClick) {
        return {
          onClick: ctaButton.onClick,
          role: 'button',
          tabIndex: '0',
        };
      }
      return;
    },
  },
});
