import { BlogCardLayoutProps } from 'libs/growth-platform-brand-system-v2/src/layouts/BlogCardLayout';
import { createSchema, morphism } from 'morphism';
import { schemaImage } from '../components/schemaImage';
import { schemaImageDisabledGatsby } from '../components/schemaImageDisabledGatsby';

export const schemaBlogCardLayout = createSchema<BlogCardLayoutProps, any>({
  linkAs: {
    path: ['as', 'Component'],
    fn: ({ as, Component }) => {
      if (as) return as;
      if (Component) return Component;
      return;
    },
  },
  title: {
    path: ['title', 'metaFields'],
    fn: ({ title, metaFields }) => title || metaFields?.title,
  },
  subtitle: {
    path: ['subtitle', 'metaFields'],
    fn: ({ subtitle, metaFields }) => subtitle || metaFields?.description,
  },
  tags: 'tags',
  capsText: 'capsText',
  logo: {
    path: ['logo'],
    fn: ({ logo }: any) => {
      if (logo) return morphism(schemaImageDisabledGatsby, logo);
      return;
    },
  },
  image: {
    path: ['image'],
    fn: ({ image }: any) => {
      if (image) return morphism(schemaImage, image);
      return;
    },
  },
  imgPosition: 'imgPosition',
  linkProps: 'linkAttributes',
});
