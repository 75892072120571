export const getCategories = (hubspotResources: any, keywords: any) => {
  const categories: any[] = [];
  const resources = filterResources(keywords, hubspotResources);
  resources.forEach(
    (resource: any) =>
      resource?.category &&
      !categories.includes(resource.category) &&
      categories.push(resource.category)
  );
  return categories;
};

export const getFormats = (hubspotResources: any, keywords: any) => {
  const formats: any[] = [];
  const resources = filterResources(keywords, hubspotResources);
  resources.forEach(
    (resource: any) =>
      resource?.format &&
      !formats.includes(resource.format) &&
      formats.push(resource.format)
  );
  return formats;
};

export const getResources = (hubspotResources: any, keywords: any) => {
  const resources = filterResources(keywords, hubspotResources);

  return resources;
};

export const filterResources = (keywords: any, hubspotResources: any) => {
  const filteredResources: any = [];
  keywords.forEach((keyword: string) => {
    const regEx = new RegExp(keyword, 'i');

    hubspotResources.forEach((resource: any, i: any) => {
      if (
        resource?.node?.widgets?.desc?.body?.section &&
        resource.node.widgets.desc.body.section.match(regEx)?.length
      ) {
        const item = resource.node;
        // do not include twice the same resource
        if (
          !filteredResources.find(
            (filteredResource: any) => filteredResource.url === item.url
          )
        ) {
          filteredResources.push({
            title: item.widgets.hero.body.title,
            btnTitle: item.widgets.desc.body.cta_label,
            url: item.url,
            category: item.widgets.desc.body.category,
            format: item.widgets.hero.body.file.format,
            tags: [
              { title: item.widgets.desc.body.category },
              { title: item.widgets.hero.body.file.format },
            ],
          });
        }
      }
    });
  });

  return filteredResources;
};
