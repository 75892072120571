import { filterResources } from './ResourcesFilter/logic';

export const removeEmptySections = (doc: any, hubspotResources: any) => {
  const sections = doc?.toolboxSections?.filter((section: any) => {
    return filterResources(
      section.relatedKeywords.split(', '),
      hubspotResources.edges,
    ).length;
  });
  return sections || [];
};
