export const applyBgColor = (color?: string): string => {
  switch (color) {
    case 'mint':
      return 'bg-mint-21';
    case 'purple':
      return 'bg-purple-21';
    case 'coral':
      return 'bg-coral-21';
    case 'azur':
      return 'bg-azur-21';
    case 'yellow':
      return 'bg-yellow-21';
    case 'white':
      return 'bg-neutral-1';
    default:
      // blue
      return 'bg-blue-21';
  }
};

export const applyBgDarkColor = (color: string) => {
  switch (color) {
    case 'mint':
      return 'bg-mint-301';
    case 'purple':
      return 'bg-purple-301';
    case 'coral':
      return 'bg-coral-301';
    case 'azur':
      return 'bg-azur-301';
    case 'yellow':
      return 'bg-yellow-301';
    case 'white':
      return 'bg-neutral-101';
    default:
      // blue
      return 'bg-blue-301';
  }
};
