import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import Layout from '../../layout';
import ResourcesFilter from './ResourcesFilter';
import {
  HeadWrapper,
} from '../../layout/HeadWrapper';
import { morphism } from 'morphism';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { removeEmptySections } from './logic';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="AllResourcesPage"
      dataName={'datoCmsAllResourcesPage'}
    />
  );
};

export const query = graphql`
  query DatoAllResourcesPage($locale: String, $id: String) {
    datoCmsAllResourcesPage(locale: $locale, id: { eq: $id }) {
      ...AllResourcesPage
    }
    allHubspotLanding(
      filter: {
        currentState: { eq: "PUBLISHED" }
        language: { eq: $locale }
        templatePath: { eq: "payfit/templates/content-landing-page.html" }
      }
    ) {
      edges {
        node {
          ...HubspotLandingPage
        }
      }
    }
  }
`;
const AllResourcesPage = ({ data, pageContext }: any) => {
  const {
    datoCmsAllResourcesPage: doc,
    allHubspotLanding: hubspotResources,
  }: any = data;
  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });
  const hero = _data?.hero;
  const heroData = morphism(schemaHero, hero);

  const resourceSections = useMemo(
    () => removeEmptySections(doc, hubspotResources),
    [doc, hubspotResources],
  );

  if (!doc) return null;
  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      customHeader={doc.header as any}
      templateName="AllResourcesPage"
    >
      {hero && <Hero {...heroData} />}
      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone {..._data} doc={{ ...doc, hubspotResources }} />
      )}

      {resourceSections.map((resourceSection: any, index: any) => (
        <ResourcesFilter
          key={index}
          section={resourceSection.label.replace(/\W+/g, '-')}
          keywords={resourceSection.relatedKeywords.split(', ')}
          hubspotResources={hubspotResources.edges as any}
          seeMoreButtonLabel={doc.seeMoreButtonLabel as any}
          categoryFilterLabel={doc.categoryFilterLabel as any}
          formatFilterLabel={doc.formatFilterLabel as any}
          noResultText={doc.noResultText as any}
          locale={pageContext.locale as any}
          sectionTitle={resourceSection.label}
          backgroundColor={resourceSection.backgroundColor}
          isDark={resourceSection.isDark}
        />
      ))}
    </Layout>
  );
};

export default AllResourcesPage;
