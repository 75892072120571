import { StructuredText as IStructuredText } from 'datocms-structured-text-utils';
import React, { ReactNode, useEffect, useState } from 'react';
import { StructuredText } from 'react-datocms';

import { ButtonV2 } from '../../../../growth-platform-brand-system-v2/src/components/ButtonV2';
import { NodeAs } from '../../../../growth-platform-brand-system-v2/src/components/NodeAs';
import {
  textBodyUppercase,
  textTitle1,
  textTitle3,
} from '../../../../growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from '../../../../growth-platform-brand-system-v2/src/utils/style';
import { PayFitColor } from '../../types/module';
import { applyBgColor, applyBgDarkColor } from '../../utils/utils';

export interface CardsListProps {
  backgroundColor: PayFitColor;
  isDark?: boolean;
  isBlogArticle?: boolean;
  surtitle?: string;
  title?: string;
  subtitle?: IStructuredText;
  cardsContainerClass?: string;
  className?: string;
  layout?: string;
  cards: ReactNode[];
  buttonType: 'minimal' | 'primary' | 'secondary';
  shouldDisplay: number;
  addSeeMoreButton?: boolean;
  seeMoreButtonLabel?: string;
  seeMoreBatchSize: number;
  id: string;
}

export const CardsList = ({
  backgroundColor = 'white',
  isDark = false,
  isBlogArticle = false,
  cards,
  surtitle,
  title,
  subtitle,
  shouldDisplay,
  layout = 'three_columns',
  addSeeMoreButton,
  seeMoreBatchSize,
  buttonType = 'secondary',
  className,
  cardsContainerClass,
  seeMoreButtonLabel = 'See more',
  id = '',
}: any) => {
  const _layout = !layout ? 'three_columns' : layout;
  const [numberOfVisibleItems, setNumberOfVisibleItems] =
    useState(shouldDisplay);

  useEffect(() => {
    setNumberOfVisibleItems(shouldDisplay);
  }, [shouldDisplay]);

  const increaseVisibleItems = () => {
    if (numberOfVisibleItems >= cards.length) return;
    setNumberOfVisibleItems((p: any) => p + seeMoreBatchSize);
  };

  const displayedCards =
    addSeeMoreButton && numberOfVisibleItems
      ? cards.slice(0, numberOfVisibleItems)
      : cards;

  return (
    <div
      className={classNames(
        'CardsList',
        className,
        isDark
          ? applyBgDarkColor(backgroundColor)
          : applyBgColor(backgroundColor),
      )}
      id={id}
    >
      <div className="container">
        {(surtitle || title || subtitle?.value) && (
          <div className=" mb-24 md:mb-40">
            {surtitle && (
              <NodeAs
                as="div"
                classNames={classNames(
                  textBodyUppercase,
                  'mb-24 text-center md:w-1/2 text-neutral-101 mx-auto',
                )}
              >
                {surtitle}
              </NodeAs>
            )}
            {title && (
              <NodeAs
                as={isBlogArticle ? 'div' : 'h2'}
                classNames={classNames(
                  textTitle1,
                  'mt-24 mb-16 md:mb-24 md:mt-0 text-center md:w-1/2 text-neutral-301 mx-auto',
                )}
                id={title.replace(/\W+/g, '-')}
              >
                {title}
              </NodeAs>
            )}
            {subtitle?.value && (
              <NodeAs
                as="div"
                classNames={classNames(
                  textTitle3,
                  'text-center lg:w-1/2 text-neutral-101 mx-auto',
                )}
              >
                <StructuredText data={subtitle} />
              </NodeAs>
            )}
          </div>
        )}
        <div
          className={classNames(
            _layout === 'two_columns' &&
              'grid md:grid-cols-2 grid-cols-1 gap-6',
            _layout === 'three_columns' &&
              'grid lg:grid-cols-3 grid-cols-1 gap-6',
            _layout === 'four_columns' &&
              'grid lg:grid-cols-4 grid-cols-1 gap-6',
            _layout === 'page_header' &&
              'grid lg:grid-cols-4 grid-cols-1 gap-6',
            cardsContainerClass,
          )}
        >
          {displayedCards && displayedCards}
        </div>
        {displayedCards && numberOfVisibleItems < cards.length && (
          <ButtonV2
            color={'default'}
            variant={buttonType}
            className="mx-auto mt-10"
            title={seeMoreButtonLabel}
            onClick={increaseVisibleItems}
          />
        )}
      </div>
    </div>
  );
};
